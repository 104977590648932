/* Angular Imports */
import { Injectable }                    from '@angular/core';
import { 
  HttpClient, 
  HttpHeaders }                          from '@angular/common/http';
import { Observable }                    from 'rxjs';
import { 
  catchError,  
  retry }                                from 'rxjs/operators';
/* OneUX Imports */
import {  
  OuxExceptionsHandlerService,
  OuxExceptionsHandleError, 
  OuxConfigService,
  OuxAuthenticationService,
  OuxWindowRefService,
  OuxLoggerService }                     from '@cisco/oux-common';
import { AuthorizationModel }            from '../models/authorization.model';


@Injectable({ providedIn: 'root' })
export class AuthorizationService {

  private gatewayUri : string;
  private userRoleUri : string = null;
  
  private windowRef: any;
  private environment : string = null;

  /**
   * Create service mapping for http exception handling
   */
  private ouxHandleError : OuxExceptionsHandleError = this.ouxExceptionsSvc.createHandleError('AuthorizationService');

  constructor(private http: HttpClient,
              private ouxConfigSvc : OuxConfigService,
              private ouxWindowSvc: OuxWindowRefService,
              private ouxAuthSvc : OuxAuthenticationService,
              private ouxExceptionsSvc : OuxExceptionsHandlerService,
              private ouxLoggerSvc: OuxLoggerService) {

    this.gatewayUri = this.ouxConfigSvc.getAppConfigValue('gatewayUri');
    this.userRoleUri = this.ouxConfigSvc.getAppConfigValue('apiUri').getUserRole;

    this.windowRef = this.ouxWindowSvc.nativeWindow;
    this.environment = this.ouxConfigSvc.getAppConfigValue('environment');
  }


  public loadUserAuthDetails() : Observable<AuthorizationModel> {

    const URL : string = `${this.gatewayUri}${this.userRoleUri}`;
    const OPTIONS = this.getOptions();
    const REQUEST$ = this.http.get<AuthorizationModel>(URL, OPTIONS)
      .pipe(
        retry(3),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('loadUserAuthDetails', error)(error);
        })
      );

    return REQUEST$;
  }

  /**
   * Return User Details
   */
  public getUserAuthDetails() : AuthorizationModel {
    let fromStorage = this.windowRef.sessionStorage.getItem('userDetails');
    let userDetails : AuthorizationModel = new AuthorizationModel({});

    if (fromStorage) {
      try {
        let json = JSON.parse(fromStorage);
        userDetails = new AuthorizationModel(json);
      }
      catch (error) {
        this.ouxLoggerSvc.logDebug('AuthorizationService: Failed to parse user details from session storage', error);
      }
    }

    return userDetails;
  }

  /**
   * Set User Details
   */
   public setUserAuthDetails(userDetails: AuthorizationModel) : void {
    this.windowRef.sessionStorage.setItem('userDetails', this.serializeDetails(userDetails));
  }

  private serializeDetails(data : AuthorizationModel) : string {
    return JSON.stringify(data);
  }

  private getOptions() : { headers : HttpHeaders } {
    const OPTIONS : { headers : HttpHeaders } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Environment': this.environment,
        'Authorization': this.ouxAuthSvc.getAuthToken(),
        'Access-Control-Allow-Origin': '*'
      })
    };

    return OPTIONS;
  }

}