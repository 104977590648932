/* Angular Imports */
import {
  Component,
  OnInit
}                                  from '@angular/core';


@Component({
  selector: 'app-toolbar-modal',
  templateUrl: './toolbar-modal.component.html',
  styleUrls: ['./toolbar-modal.component.scss'],
  host: {
    'class': 'toolbar-modal'
  }
})
export class ToolbarModalComponent implements OnInit {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  /**
   * Define modal header content
   */
  private headerContent : any = {
    icon: 'icon-sign-out',
    heading: 'Logout'
  };
  public get getHeaderContent() : any {
    return this.headerContent;
  }

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor() { }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {}

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////


  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

}
