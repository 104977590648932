/* Angular Imports */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
/* Package Imports */
import {
  OuxAuthenticationService,
  OuxModalService } from '@cisco/oux-common';


/* Component Meta */
@Component({
  selector: 'app-logout',
  templateUrl: 'logout.component.html',
  styleUrls: ['logout.component.scss'],
  host: {
    'class': 'app-logout'
  }
})
export class RootLogoutComponent implements OnInit {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private router: Router,
              private authSvc: OuxAuthenticationService,
              private modalSvc: OuxModalService) {

  }

  ////////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////////

  ngOnInit(): void {}

  ////////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////////

  public onLogout(): void {
    this.authSvc.expireAuthToken();
    this.router.navigate(['/login']);
    this.closeModal();
  }

  public onCancel(): void {
    this.closeModal();
  }

  ////////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////////

  /**
   * Demo purposes only
   * Shows how to close the modal manually
   */
  private closeModal(): void {
    this.modalSvc.closeModal();
  }

}
