/* Angular Import */
import { Injectable }                           from '@angular/core';
/* RxJs Imports */
import { 
  BehaviorSubject, 
  Observable }                                  from 'rxjs';
/* Feature Imports */
import { 
  OuxCommonService, 
  OuxLoggerService, 
  OuxStore }                                    from '@cisco/oux-common';
import { OuxOverlayNavMainModel }               from '../models/oux-overlay-nav-main.model';


/**
 * Creates our OverlayNav State injectable
 * Feature specific stores are Angular Injectables extending the abstract OuxStore (i.e., class):
 */
@Injectable()
export class OuxOverlayNavStore extends OuxStore<OuxOverlayNavMainModel[]> {

  /**
   * Stage action stream to enable multicast strategy
   */
  private selectedItemDataSubject : BehaviorSubject<OuxOverlayNavMainModel>;
  private showSubNavSubject : BehaviorSubject<boolean>;
  /**
   * Stand up our selected nav item action stream (will retain the next emitted value)
   * This allows for us to react to changes made to the model
   */
  public selectedItemData$ : Observable<OuxOverlayNavMainModel>;
  public showSubNav$ : Observable<boolean>;

  constructor(private ouxLoggerSvc : OuxLoggerService,
              private ouxCommonSvc : OuxCommonService) {

    super ([]);

    this.selectedItemDataSubject = new BehaviorSubject(null);
    this.selectedItemData$ = this.selectedItemDataSubject.asObservable();

    this.showSubNavSubject = new BehaviorSubject(false);
    this.showSubNav$ = this.showSubNavSubject.asObservable();
  }

  /**
   * Default Method
   * ============================================================
   */

  public setNavData(data: OuxOverlayNavMainModel[]) : void {
    super.setState(data);
    this.ouxLoggerSvc.logDebug('OuxOverlayNavStore - OuxOverlayNavMainModel State:', this.state);
  }


  /**
   * Convenience Methods
   * ============================================================
   */

  public get selectedItemDetails() : OuxOverlayNavMainModel {
    return this.selectedItemDataSubject.getValue();
  }

  public get showSubNav() : boolean {
    return this.showSubNavSubject.getValue();
  }

  public closeSubNav() : void {
    this.showSubNavSubject.next(false);
  }

  public openSubNav() : void {
    this.showSubNavSubject.next(true);
  }

  public setSelectedSubNavData(compareKey : string, key : string | number, ) : void {
    this.ouxCommonSvc.filterArray<any>(this.state as OuxOverlayNavMainModel[], compareKey, key)
      .then( 
        (results : OuxOverlayNavMainModel) => {
          this.successPath(results)
        }, 
        (this.errorPath)
      );
  }
  
  private successPath(results : OuxOverlayNavMainModel) : void {
    this.selectedItemDataSubject.next(results);
    this.showSubNavSubject.next(true);
    this.ouxLoggerSvc.logDebug('OuxOverlayNavComponent - Selected Item Data:', this.selectedItemDetails);
  }

  private errorPath(_) {
    this.showSubNavSubject.next(false);
    this.ouxLoggerSvc.logError(`OuxOverlayNavComponent - Id not found!`);
  };

}