import {
  Pipe,
  PipeTransform }               from '@angular/core';
import { DatePipe }             from '@angular/common';
import { DATE_TIME_FORMAT }     from '../models/constants';

@Pipe({
  name: 'dateFormat'
})
/**
 * Transforms inbound date into specified format via DATE_TIME_FORMAT
 *
 * Ex: {{ expirationDate | dateFormat }}
 */
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value : any, args? : any) : any {
    return super.transform(value, DATE_TIME_FORMAT.DATE_FMT);
  }
}
