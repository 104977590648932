/* ES6/TypeScript Imports */
import { NgModule }                               from '@angular/core';
import { BrowserModule }                          from '@angular/platform-browser';
import { BrowserAnimationsModule }                from '@angular/platform-browser/animations';
import {
  HttpClient,
  HttpClientModule }                              from '@angular/common/http';

/* NGX-Translate Imports */
import {
  TranslateModule,
  TranslateLoader }                               from '@ngx-translate/core';
import { MultiTranslateHttpLoader }               from './shared/decorators/multi-translation-http-loader';

/* Root Module Dependancies */
import { AppRoutingModule }                       from './app-routing.module';
import { AppComponent }                           from './app.component';
import { NotAuthorizedComponent }                 from './routes/notauthorized/notauthorized.component';
import { NotFoundComponent }                      from './routes/notfound/notfound.component';

/* Global Modules */
import {
  OuxConfigModule,
  OuxModalModule,
  OuxNotAuthorizedPartialModule,
  OuxNotFoundPartialModule, 
  OuxThemeColors, 
  OuxThemeMode, 
  OuxThemeModel, 
  OuxThemeModule,
  OuxThemeOptions }                               from '@cisco/oux-common';

/* Feature Modules */
import { CoreModule }                             from './core/core.module';
import { GuardsModule }                           from './guards/guards.module';
import { InterfaceModule }                        from './interface/interface.module';
import { SharedModule }                           from './shared/shared.module';


/* AOT requires an exported function for factories */
export function HttpLoaderFactory(http : HttpClient) {
  return new MultiTranslateHttpLoader( http, [
      {
        prefix: './assets/i18n/core/',
        suffix: '.json'
      },
      {
        prefix: './assets/i18n/',
        suffix: '.json'
      }
  ]);
}

/* Theming Support - Light Theme */
export const oneUxLightTheme: OuxThemeModel = {
  name: 'oux-portal-light',
  display: 'OneUX Portal Light Theme',
  mode: OuxThemeMode.Light,
  properties: {
    background: '#f7f9fa',
    onBackground: '#58585B',
    surface: OuxThemeColors.grayscale.white,
    onSurface: '#58585B',
    divider: OuxThemeColors.grayscale.gray300,
    link: OuxThemeColors.misc.link,
    primary: '#429df3',
    onPrimary: OuxThemeColors.grayscale.white,
    secondary: OuxThemeColors.theme.secondary,
    onSecondary: OuxThemeColors.grayscale.white,
    tertiary: OuxThemeColors.theme.tertiary,
    onTertiary: OuxThemeColors.grayscale.white,
    info: OuxThemeColors.status.info,
    onInfo: OuxThemeColors.grayscale.black,
    error: OuxThemeColors.theme.danger,
    onError: OuxThemeColors.grayscale.white,
    warning: OuxThemeColors.theme.warning,
    onWarning: OuxThemeColors.grayscale.black,
    success: OuxThemeColors.theme.success,
    onSuccess: OuxThemeColors.grayscale.black,
    neutral: OuxThemeColors.theme.light,
    onNeutral: OuxThemeColors.grayscale.black
  }
};

/* Theming Support - Dark Theme */
export const oneUxDarkTheme: OuxThemeModel = {
  name: 'oux-portal-dark',
  display: 'OneUX Portal Dark Theme',
  mode: OuxThemeMode.Dark,
  properties: {
    background: OuxThemeColors.grayscale.gray900,
    onBackground: OuxThemeColors.grayscale.white,
    surface: OuxThemeColors.grayscale.gray800,
    onSurface: OuxThemeColors.grayscale.white,
    divider: OuxThemeColors.grayscale.gray700,
    link: OuxThemeColors.misc.link,
    primary: '#429df3',
    onPrimary: OuxThemeColors.grayscale.white,
    secondary: OuxThemeColors.misc.link,
    onSecondary: OuxThemeColors.grayscale.white,
    tertiary: OuxThemeColors.grayscale.white,
    onTertiary: OuxThemeColors.grayscale.black,
    info: OuxThemeColors.status.info,
    onInfo: OuxThemeColors.grayscale.black,
    error: OuxThemeColors.theme.danger,
    onError: OuxThemeColors.grayscale.white,
    warning: OuxThemeColors.theme.warning,
    onWarning: OuxThemeColors.grayscale.black,
    success: OuxThemeColors.theme.success,
    onSuccess: OuxThemeColors.grayscale.black,
    neutral: OuxThemeColors.theme.dark,
    onNeutral: OuxThemeColors.grayscale.white
  }
};

/* Theme config */
export const themeOptions: OuxThemeOptions = {
  themes: [oneUxLightTheme, oneUxDarkTheme],
  active: oneUxLightTheme.name,
  rememberTheme: true
};


@NgModule({

  /**
   * Import Angular modules we depend on
   */
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,

    // Translation Deps
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    OuxThemeModule.forRoot(themeOptions),

    OuxConfigModule,
    OuxModalModule,
    OuxNotAuthorizedPartialModule,
    OuxNotFoundPartialModule,

    CoreModule,
    GuardsModule,
    InterfaceModule,
    SharedModule
  ],

  /**
   * Declare components, directives and pipes (i.e., filters)
   */
  declarations: [
    AppComponent,
    NotAuthorizedComponent,
    NotFoundComponent,
  ],

  /**
   * Provide services to app root injector
   */
  providers: [

  ],

  /*
   * Bootstrap root component
   * This tells the compiler that this is an entry component and it should generate code to bootstrap the application with it.
   */
  bootstrap: [
    AppComponent
  ]

})

export class AppModule { }
