/* Angular Import */
import { Injectable }                           from '@angular/core';
/* Feature Imports */
import { 
  OuxLoggerService, 
  OuxStore }                                    from '@cisco/oux-common';
import { AuthorizationModel }                   from '../models/authorization.model';


/**
 * Creates our Authorization State injectable
 * Feature specific stores are Angular Injectables extending the abstract OuxStore (i.e., class):
 */
@Injectable({ providedIn: 'root' })
export class AuthorizationStore extends OuxStore<AuthorizationModel> {

  constructor(private ouxLoggerSvc : OuxLoggerService) {

    super (new AuthorizationModel({}));

  }

  /**
   * Default Method
   * ============================================================
   */

  public setUserDetails(userDetails: AuthorizationModel) : void {
    super.setState(userDetails);
    this.ouxLoggerSvc.logDebug('AuthorizationStore: User Details', this.state);
  }

}