<ng-container *ngIf="( item?.configs && item?.configs.length ); else exceptionTemplate">
  <ul class="list --bare">
    <li 
      *ngFor="let childItem of item.configs | ouxSearchFilter : searchValue : 'name'"
      class="item flex --center-items"
      [ngClass]="{ 'is-active' : rla.isActive }">
      <a class="item__link flex"
        [routerLink]="[childItem.link]"
        routerLinkActive 
        #rla="routerLinkActive"
        [attr.data-selector]="childItem.name">
        <span class="name flex --center-items --even-spaced">
          <span
            [ouxTooltip]="childItem.name.length > truncateName ? childNameTemplate : ''"
            [ouxTooltipOptions]="tooltipOptions">
            {{ childItem.name | truncateString : truncateName : '...' }}
          </span>
          <ng-template #childNameTemplate>
            {{ childItem.name }}
          </ng-template>
          <i class="icon icon-eye"></i>
        </span>
        <ng-container *ngIf="childItem.auditDescription">
          <p 
            class="desc"
            [ouxTooltip]="childItem.auditDescription.length > truncateName ? childDescTemplate : ''"
            [ouxTooltipOptions]="tooltipOptions">
            {{ childItem.auditDescription | truncateString : truncateName : '...' }}
          </p>
          <ng-template #childDescTemplate>
            {{ childItem.auditDescription }}
          </ng-template>
        </ng-container>
      </a>
    </li><!-- /childItem loop -->
    <li class="item flex --center-items" *ngIf="(item.configs | ouxSearchFilter : searchValue : 'name').length === 0;">
      <p class="message fadeInDown animated">Hmm... Try using a different search term.</p>
    </li>
  </ul>
</ng-container>

<ng-template #exceptionTemplate>
  <ul class="list --bare">
    <li class="item flex --center-items disable-hover">
      <p class="message fadeInDown animated">No {{ selectedFilter }} records currently assigned to {{ item?.tenant }}</p>
    </li>
  </ul>
</ng-template>