/* Angular Imports */
import { Component, OnInit }                from '@angular/core';
/* Package Imports */
import { OuxModalService }                  from '@cisco/oux-common';
/* Feature Imports */
import { ToolbarModalComponent }            from './toolbar-modal/toolbar-modal.component';
import { InstanceService }                  from '../../shared/services/instance.service';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  host: {
    'class': 'app-toolbar'
  }
})
export class ToolbarComponent implements OnInit {


  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private modalSvc : OuxModalService,
              private instanceSvc : InstanceService) {

   }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void { 
    this.instanceSvc.instanceMountedAt('ToolbarComponent', 'app-toolbar');
  }

  ////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////

  public openModal () {
    this.modalSvc.openModal(ToolbarModalComponent);
  }

}