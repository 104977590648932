/* Angular Imports */
import { Component, OnInit }        from '@angular/core';
/* Package Imports */
import { OuxModalService }          from '@cisco/oux-common';
/* Feature Imports */
import { ToolbarModalComponent }    from '../toolbar-modal/toolbar-modal.component';


@Component({
  selector: 'toolbar-settings',
  templateUrl: './toolbar-settings.component.html',
  styleUrls: ['./toolbar-settings.component.scss'],
  host: {
    'class': 'toolbar-settings'
  }
})
export class ToolbarSettingsComponent implements OnInit {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private modalSvc : OuxModalService) {}

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {}

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public openModal() {
    this.modalSvc.openModal(ToolbarModalComponent);
  }

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

}
