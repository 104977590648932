/* Angular Imports */
import { Component, OnInit } from '@angular/core';
/* Package Imports */
import { OuxConfigService } from '@cisco/oux-common';
/* Feature Imports */
import { InstanceService } from '../../shared/services/instance.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  host: {
    'class': 'app-login'
  }
})
export class RootLoginComponent implements OnInit {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  public header = 'DVA Audit Framework';
  public message = 'Cisco uses your network username and password to login. Continue to DVA Audit login through your network.';
  public redirectAfter = '/tenant';

  /**
   * The following properties are managed in 'app/config/app.{environment}.json'
   */
  public gatewayUri: string;
  public enableDeepLinking: boolean;

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private configSvc: OuxConfigService,
              private instanceSvc: InstanceService) {

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit(): void {

    this.instanceSvc.instanceMountedAt('LoginComponent', 'app-login');
    /**
     * Init login configuration
     */
    this.configureLogin();

  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////


  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  private configureLogin(): void {
    this.gatewayUri = this.configSvc.getAppConfigValue('gatewayUri');
    this.enableDeepLinking = this.configSvc.getAppConfigValue('enableDeepLinking');
  }

}
