import { Pipe, PipeTransform }     from '@angular/core';
// Alternative approach: https://stackoverflow.com/questions/41672578/filter-on-multiple-columns-using-one-pipe-angular-2

@Pipe({
  name: 'ouxSearchFilter',
  pure: true // enables synchronous filter - Note: may impact performance if item count is too large 
})

export class OuxOverlayNavSearchPipe implements PipeTransform {
 
  transform(items : any[], filterBy : string, propName : any) : any[] {
    
    filterBy = filterBy ? filterBy.toLocaleLowerCase() : null;

    return filterBy ? items.filter( (item : any) => 
      item[propName].toLocaleLowerCase().indexOf(filterBy) !== -1) : items;
  }

}