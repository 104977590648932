import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateLastRunFormat',
  pure: true
})
export class DateLastRunFormatPipe implements PipeTransform {
  public transform(value : string) : string {

    var truncatedDateString = "";
    var truncateArray = value.split(".");
    truncatedDateString = truncateArray.splice(0,truncateArray.length - 1).join('.');
    return truncatedDateString;
  }
}