<div class="user">
  
  <toolbar-theme></toolbar-theme>

  <button 
    class="btn --stateless"
    (click)="toggleDropdown()"
    (blur)="close = false"
    [ouxTooltip]="settingsComponent"
    [ouxTooltipOptions]="{ theme: 'light', position: 'bottom', enableClick: true }"
    data-selector="toolbar-aat8Ts4WDj">
    <span class="toggle" [ngClass]="{ '--close' : close }">&nbsp;</span>
  </button>

  <span class="user__name" data-selector="toolbar-m2zcokWqKN">{{ currentUser }}</span>

  <span class="user__avatar" [ngClass]="{ '--initials': setInitials }" data-selector="toolbar-kABOLFCQtf">
    <img 
      *ngIf="!setInitials" 
      class="img-responsive" 
      [src]="userAvatar" 
      alt="Directory photo of {{ currentUser }}"/>
    <span *ngIf="setInitials">{{ userInitial }}</span>
  </span>

</div>