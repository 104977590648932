<div class="form__section">
  <div class="input-group">
    <input 
      #searchField 
      autocomplete="off" 
      type="search" 
      name="search"
      [placeholder]="placeholder || 'Type to search'"  
      [(ngModel)]="searchValue"
      (ngModelChange)="onSearchChange()"
      data-selector="oux-overlay-nav-search-S2HM0vJLwq"/>
      <span class="addon addon--icon add-divider --left" [ngSwitch]="searchField.value.length > 0">
        <button 
          *ngSwitchCase="false" 
          class="btn --stateless"
          data-selector="oux-overlay-nav-search-Gt6HjC00Ii">
          <i class="icon-search fadeIn animated"></i>
        </button>
        <button 
          *ngSwitchCase="true" 
          class="btn --stateless" 
          (click)="onClearSearch()"
          data-selector="oux-overlay-nav-search-520OEtEQH8">
          <i class="icon-close fadeIn animated"></i>
        </button>
      </span>
  </div>
</div>