import { 
  Component, 
  OnInit, 
  Input }                           from '@angular/core';
import { OuxOverlayNavMainModel }   from '../../models/oux-overlay-nav-main.model';
import { 
  OuxTooltipOptions, 
  OuxTooltipPosition, 
  OuxThemeType }                    from '@cisco/oux-common';


@Component({
  selector: 'oux-overlay-nav-sub-list',
  templateUrl: './oux-overlay-nav-sub-list.component.html',
  styleUrls: [
    './oux-overlay-nav-sub-list.component.scss'
  ],
  host: {
    'class': 'oux-overlay-nav-sub-list'
  }
})
export class OuxOverlayNavSubListComponent implements OnInit {

  /**
   * Manages the limit on truncated text
   */
   public truncateName : number = 25;

  /**
   * Represents the inbound resources
   */
  @Input('data')
  public item : OuxOverlayNavMainModel;

  @Input('searchOn')
  public searchValue : string;

  @Input('selectedFilter')
  public selectedFilter : string;

  public tooltipOptions:  OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Right,
    theme: OuxThemeType.Surface,
    cssOverrides: {  
      'max-width': '400px' 
    },
  }); 

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor() {}

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {}

}