/* Angular Imports */
import { Injectable }           from '@angular/core';
/* RxJS Imports */
import { BehaviorSubject, of }  from 'rxjs';
import { delay, delayWhen }     from 'rxjs/operators';


@Injectable()
export class LoaderService {
  private totalRequests = 0;
  private isLoading = new BehaviorSubject<boolean>(false);

  public isLoading$ = this.isLoading.asObservable()
    .pipe(
      delayWhen(value => value == true ? of(value) : of(value).pipe(delay(400)))
    );

  constructor() { }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  /**
   * Loading Interceptor will change the subject value to true, when a request starts
   */
  public show() : void {
    this.totalRequests++;

    this.isLoading.next(true);
  }

  /**
   * Loading Interceptor will “hide” it when the request is “finalized”
   */
  public hide() : void {
    if (this.totalRequests > 0) {
      this.totalRequests--;
    }

    if (this.totalRequests == 0) {
      this.isLoading.next(false);
    }
  }

}
