<oux-generic-dropdown [closeOnClick]="false"> 
  <button class="theme-trigger" ouxGenericDropdownTriggerContainer ouxGenericDropdownTrigger> 
    <i class="icon icon-hue"></i> 
  </button> 
 
  <div class="theme-selector-container"> 
    <oux-radio-button-item class="theme-selector" *ngFor="let item of themeRadioItems" 
        [item]="item.radio"  
        [name]="'theme'"  
        [(ngModel)]="selectedTheme" 
        (ngModelChange)="selectTheme()"> 
      <div class="theme-preview" [style.background]="'linear-gradient(135deg, ' + item.primary + ' 50%, ' + item.background + ' 50%)'"></div> 
    </oux-radio-button-item> 
  </div> 
</oux-generic-dropdown>