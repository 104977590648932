/* Angular Imports */
import { 
  Component, 
  OnInit,
  OnDestroy, 
  Input, 
  HostBinding, 
  ViewChild }                                     from '@angular/core';
  import { Router }                               from '@angular/router';
/* RxJs Imports */
import { Subscription }                           from 'rxjs';
/* OneUX Imports */
import { OuxLoggerService }                       from '@cisco/oux-common';
/* Feature Imports */
import { OuxOverlayNavStore }                     from '../../stores/oux-overlay-nav.store';
import { OuxOverlayNavOptions }                   from '../../models/oux-overlay-nav.options';
import { OuxOverlayNavMainModel }                 from '../../models/oux-overlay-nav-main.model';
import { OuxOverlayNavSearchComponent }           from '../../partials/oux-overlay-nav-search/oux-overlay-nav-search.component';



@Component({
  selector : 'oux-overlay-nav-sub',
  templateUrl : './oux-overlay-nav-sub.component.html',
  styleUrls : [
    './oux-overlay-nav-sub.component.scss'
  ],
  host: {
    'class': 'oux-overlay-nav-sub' 
  },
})
export class OuxOverlayNavSubComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  public selectedTab: string;

  public searchValue : string = null;
  /**
   * Filters setup
   */
  public subListHeaderText: string;
  public subListSubHeaderText: string;
  public subListData: OuxOverlayNavMainModel;
  public filterOneData: OuxOverlayNavMainModel;
  public filterTwoData: OuxOverlayNavMainModel;
  public filterOneItemCount : string;
  public filterTwoItemCount : string;

  /**
   * Options (i.e. component config) passed down from OuxOverlayNavBaseComponent
   */
  @Input('options')
  public options : OuxOverlayNavOptions;
  
  /**
   * Creates a host binding on the object property showSubNav
   * The following allows us to leverage Angular's own change detection
   */
  @HostBinding('class.--show')
  public showSubNav : boolean;
  // Replace
  // public get showNavSub() : boolean {
  //   return this.options && this.options.showSubNav;
  // }

  @ViewChild(OuxOverlayNavSearchComponent, { read : OuxOverlayNavSearchComponent })
  private searchComponent : OuxOverlayNavSearchComponent;

  constructor(private router : Router,
              private ouxOverlayNavStore : OuxOverlayNavStore,
              private ouxLoggerSvc : OuxLoggerService) {

      /**
       * Init showNav behavior stream
       */
      this.subscriptions.push(
        this.ouxOverlayNavStore.showSubNav$.subscribe(value => {
          this.showSubNav = value;
        })
      );
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {
    this.fetchSubNavItems();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public closeSubNav() : void {
    this.goToTenantOverview();
    this.ouxOverlayNavStore.closeSubNav();
  }

  /**
   * Toggles Nav Sub options dropdown
   */
  public toggleOptions() : void {}

  public search(value : string) : void {
    this.searchValue = value;
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public setSelectedTab(tab: { id: string, label: string, metadata: any }) : void {
    this.selectedTab = tab.label;
    // hooking into public method on OuxOverlayNavSearchComponent
    this.searchComponent.onClearSearch();
  }


  private fetchSubNavItems() : void {
    // Extracts filters items based on selected main nav item
    this.subscriptions.push(
      this.ouxOverlayNavStore.selectedItemData$.subscribe( 
        (selectedItem : OuxOverlayNavMainModel)  => {
          if (!selectedItem) {
            return;
          }
          this.ouxLoggerSvc.logDebug(`OuxOverlayNavSubComponent - ${selectedItem.tenant} (Pre filtered):`, selectedItem);
          // the assignment
          this.buildFilterBreakoutLists(selectedItem);
        }
      )
    );
  }

  private buildFilterBreakoutLists(selectedItem: OuxOverlayNavMainModel) : void {
    this.subListHeaderText = selectedItem.tenant;
    this.subListSubHeaderText = selectedItem.tenant || `${selectedItem.tenant.length}: associated records`;
    this.ouxLoggerSvc.logDebug('OuxOverlayNavSubComponent - Enable Sub Filter:', this.options.enableSubFilter);
    // with filters enabled
    if (this.options.enableSubFilter) {
      // create breakout items
      this.filterOneData = new OuxOverlayNavMainModel(selectedItem);
      this.filterTwoData = new OuxOverlayNavMainModel(selectedItem);
      // assign data based on flag value
      this.filterOneData.configs = selectedItem.configs.filter(child => child.active == true);
      this.filterTwoData.configs = selectedItem.configs.filter(child => child.active == false);
        this.ouxLoggerSvc.logDebug(`OuxOverlayNavSubComponent - ${selectedItem.tenant} (Active list):`, this.filterOneData.configs);
        this.ouxLoggerSvc.logDebug(`OuxOverlayNavSubComponent - ${selectedItem.tenant} (Inactive list):`, this.filterTwoData.configs);
      // create meta count for tabs
      this.filterOneItemCount = `(${this.filterOneData.configs.length})`;
      this.filterTwoItemCount = `(${this.filterTwoData.configs.length})`;
    }
    else {
      this.subListData = new OuxOverlayNavMainModel(selectedItem);
    }
  }

  /**
   * Navigate back to the overview - sub view
   */
   private goToTenantOverview() : void {
    this.router.navigate(['/tenant']);
  }

}