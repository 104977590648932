/* Angular Imports */
import {
  Component,
  OnInit,
  OnDestroy }                                   from '@angular/core';
/* RxJS Imports */
import { Subscription }                         from 'rxjs';
/* OneUX Imports */
import {
  OuxLayoutService,
  OuxLoggerService }                            from '@cisco/oux-common';
import { LoaderService }                        from '../core/services/loader.service';
import { InterfaceBase }                        from './interface.base';
import { InstanceService }                      from '../shared/services';


@Component({
  selector: 'app-interface',
  templateUrl: './interface.component.html',
  styleUrls: ['./interface.component.scss'],
  host: {
    'class': 'app-interface'
  },
})
export class InterfaceComponent extends InterfaceBase implements OnInit, OnDestroy {

  public isLoading: boolean;

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(ouxLayoutSvc: OuxLayoutService,
              ouxLoggerSvc: OuxLoggerService,
              instanceSvc: InstanceService,
              loaderSvc: LoaderService) {
    super(
      ouxLayoutSvc,
      ouxLoggerSvc,
      instanceSvc,
      loaderSvc
    );

    this.subscriptions.push(
      this.loaderSvc.isLoading$.subscribe( (loading : boolean) => {
        this.isLoading = loading;
        this.ouxLoggerSvc.logDebug('Is loading...', this.isLoading);
    }));
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit(): void {
    this.instanceSvc.instanceMountedAt('InterfaceComponent', 'app-interface');
    this.setLayoutStates();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach( (sub: Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }


  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  /**
   * Convenience function. Responsible for envoking all state initialization code
   */
  private setLayoutStates(): void {
    this.checkSideNavState();
    this.checkFooterState();
  }

  /**
   * Adds --full-width class to interface template if showSideNav is false
   */
  private checkSideNavState(): void {
    this.subscriptions.push(
      this.ouxLayoutSvc.getSideNavState()
        .subscribe( (state: boolean) => {
          this.showSideNav = state;
        })
    );
  }

  /**
   * Adds --has-footer class to interface template if showFooter is true
   */
  private checkFooterState(): void {
    this.subscriptions.push(
      this.ouxLayoutSvc.getFooterState()
        .subscribe( (state: boolean) => {
          this.showFooter = state;
        })
    );
  }

}
