/* Angular Imports */
import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
  OnChanges,
  SimpleChanges } from '@angular/core';

@Directive({
  selector: '[transitionHeight]'
})
export class TransitionHeightDirective implements OnChanges {

  // Data values are passed in by an external event
  @Input('transitionHeight')
  public data: { isCollapsed: boolean, initialHeight?: number };

  private nativeElem: HTMLElement;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2) {

    this.nativeElem = this.elRef.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges) {

    this.checkElementState(this.data.isCollapsed);
  }

  /**
   * Check the current state of the collapsable item
   */
  public checkElementState(isCollapsed: boolean) : void {

    if (isCollapsed) {
      this.expandElement(this.nativeElem);
    } 
    else {
      this.collapseElement(this.nativeElem);
    }

  }

  /**
   * Handle collapse events
   */
  public collapseElement(element: HTMLElement) : void {

    // Get the height of the element's inner content, regardless of its actual size
    const ELEMENT_HEIGHT = element.scrollHeight;

    /**
     * On the next frame (as soon as the previous style change has taken effect),
     * explicitly set the element's height to its current pixel height, so we
     * aren't transitioning out of 'auto'
     */
    requestAnimationFrame(() => {

      this.renderer.setStyle( this.nativeElem, 'height', ELEMENT_HEIGHT + 'px');

      /**
       * On the next frame (as soon as the previous style change has taken effect),
       * have the element transition to height: 0
       */
      requestAnimationFrame(() => {

        if (this.data.initialHeight) {
          this.renderer.setStyle( this.nativeElem, 'height', this.data.initialHeight + 'px' );
        }
        else {
          this.renderer.setStyle( this.nativeElem, 'height', '0px' );
        }
        
      });

    });

    this.data.isCollapsed = true;

  }

  /**
   * Handle expand events
   */
  public expandElement(element: HTMLElement) : void {

    // Get the height of the element's inner content, regardless of its actual size
    const ELEMENT_HEIGHT = element.scrollHeight;

    // Have the element transition to the height of its inner content
    this.renderer.setStyle( this.nativeElem, 'height', ELEMENT_HEIGHT + 'px');

    this.data.isCollapsed = false;

  }

}
