import {
  HttpClient, 
  HttpHeaders }                         from '@angular/common/http';
import { Injectable }                   from '@angular/core';
import { Observable }                   from 'rxjs';
import { 
  catchError, 
  shareReplay, 
  tap }                                 from 'rxjs/operators';
import { 
  OuxLoggerService, 
  OuxExceptionsHandlerService, 
  OuxExceptionsHandleError, 
  OuxAuthenticationService, 
  OuxConfigService }                    from '@cisco/oux-common';


@Injectable({ providedIn: 'root' })
export class SharedDataService {


  private gatewayUri: string;
  private auditConfigsFilterUri: string;

  private ouxHandleError : OuxExceptionsHandleError = this.ouxExceptionsHandlerSvc.createHandleError('SharedDataService');

  constructor(private http : HttpClient,
              private ouxAuthSvc : OuxAuthenticationService,  
              private ouxConfigSvc : OuxConfigService,
              private ouxLoggerSvc : OuxLoggerService, 
              private ouxExceptionsHandlerSvc : OuxExceptionsHandlerService, ) {

    this.gatewayUri = this.ouxConfigSvc.getAppConfigValue('gatewayUri');
    this.auditConfigsFilterUri = this.ouxConfigSvc.getAppConfigValue('apiUri').auditConfigsFilter;
  }

  /**
   * Retrieves a list of non application user from our Impact Core API
   */
  public getACVData(environment : any, tenants: string) : Observable<any> {

    const URL : string = `${this.gatewayUri}${this.auditConfigsFilterUri}`;
    const OPTIONS = this.getOptions(environment, tenants);

    return this.http.get<any>(URL, OPTIONS)
      .pipe(
        tap(response => this.ouxLoggerSvc.logDebug('SharedDataService: getACVData()', response)),
        shareReplay(),
        catchError(error =>
          // create operation mapping for http exception handling
          this.ouxHandleError('getACVData', error)(error)
        )
      );
  }

  private getOptions(environment, tenants) : { headers: HttpHeaders} {
    const OPTIONS : { headers : HttpHeaders } = {
      headers : new HttpHeaders()
        .set('Authorization', this.ouxAuthSvc.getAuthToken())
        .append('Content-Type', 'application/json')
        .append('Accept', 'application/json')
        .append('Environment', environment)
        .append('Tenants', tenants)
    };

    return OPTIONS;
  }
      
}


