<div class="settings">
  <ul class="settings__list">
    <li class="item">
      <span class="item__label">Settings</span>
      <i class="item__icon ui-icon-sliders"></i>
    </li>
    <li class="item" (click)="openModal()">
      <span class="item__label">Logout</span>
      <i class="item__icon icon-power"></i>
    </li>
  </ul>
</div>