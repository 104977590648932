/* Angular Imports */
import {
  Component,
  OnInit,
  OnDestroy }                                         from '@angular/core';
import { Subscription }                               from 'rxjs';
/* Package Imports */
import {
  OuxRouterService,
  OuxLoggerService }                                  from '@cisco/oux-common';
import { InstanceService }                            from './shared/services';


@Component({
  selector: 'app-shell',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  host: {
    'class': 'app-shell'
  }
})
export class AppComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private ouxRouterSvc: OuxRouterService,
              private ouxLoggerSvc: OuxLoggerService,
              private instanceSvc: InstanceService) {

    // Initializes the route history feature
    this.ouxRouterSvc.loadRouteHistory();
    // Initializes the dynamic title builder feature
    this.ouxRouterSvc.initDynamicTitleBuilder();
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit(): void {

    this.instanceSvc.instanceMountedAt('AppComponent', 'app-shell');

    /**
     * Return the lastest route tree
     */
    this.subscriptions.push(
      this.ouxRouterSvc.getRouteHistory()
        .subscribe(
          (history: string) => {
            this.ouxLoggerSvc.logDebug('AppComponent: Route History', history);
      })
    );

    /**
     * Returns a boolean
     * Title changed due to route changes (true/false)
     */
    this.subscriptions.push(
      this.ouxRouterSvc.routeTitleChanged()
        .subscribe(
          (value: boolean) => {
            this.ouxLoggerSvc.logDebug('AppComponent: Route Changed', value);
        })
    );

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

}
