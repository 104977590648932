import { OuxOverlayNavMainModel } from './oux-overlay-nav-main.model';
import { OuxOverlayMainHeaderGroupModel } from './oux-overlay-nav-main-header-group.model';
import { OuxOverlayNavFilterOptionsModel } from './oux-overlay-nav-filter-options.model';
import { OuxOverlayNavApiOptionsModel } from './oux-overlay-nav-api-options.model';
import { OuxOverlayNavSearchOptionsModel } from './oux-overlay-nav-search-options.model';

export class OuxOverlayNavOptions {

  public entryRoute: string;
  public navItems?: OuxOverlayNavMainModel[];
  public enableMainSearch?: boolean;
  public enableSubSearch?: boolean;
  public enableSubFilter?: boolean;
  public navMainHeaderGroup?: OuxOverlayMainHeaderGroupModel;
  public filterOptions?: OuxOverlayNavFilterOptionsModel;
  public apiRequestOptions?: OuxOverlayNavApiOptionsModel;
  public searchOptions?: OuxOverlayNavSearchOptionsModel;
  public awaitMessage: string;
  public showSubNav?: boolean;
  public overrideStoreState?: boolean;

  constructor(opts: Partial<OuxOverlayNavOptions>) {
    Object.assign(this, opts);
  }

}