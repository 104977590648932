/* Angular Imports */
import {
  Component,
  OnInit,
  OnDestroy,
  ViewContainerRef }                            from '@angular/core';
import { Router }                               from '@angular/router';
/* RxJS Imports */
import { Subscription }                         from 'rxjs';
/* OneUX Imports */
import { OuxLayoutService }                     from '@cisco/oux-common';
/* Feature Imports */
import { InstanceService }                      from '../../shared/services/instance.service';
import { OuxOverlayNavOptions }                 from '../oux-overlay-nav/models/oux-overlay-nav.options';
import { OuxOverlayMainHeaderGroupModel }       from '../oux-overlay-nav/models/oux-overlay-nav-main-header-group.model';
import { OuxOverlayNavFilterOptionsModel }      from '../oux-overlay-nav/models/oux-overlay-nav-filter-options.model';
import { OuxOverlayNavApiOptionsModel }         from '../oux-overlay-nav/models/oux-overlay-nav-api-options.model';
import { OuxOverlayNavSearchOptionsModel }      from '../oux-overlay-nav/models/oux-overlay-nav-search-options.model';
import { OuxOverlayNavMainModel }               from '../oux-overlay-nav/models/oux-overlay-nav-main.model';


@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
  host: {
    'class': 'app-aside'
  }
})
export class AsideComponent implements OnInit, OnDestroy {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  /**
   * Manage subscription - remember to unsubscribe when component is destroyed
   */
  private subscriptions: Subscription[] = [];

  /**
   * Stand up native element ref
   */
  private aside: HTMLElement | any;
  /**
   * Is side nav present
   */
  public showSideNav = true;

  public navLinks: OuxOverlayNavMainModel[] = [];

  public ouxOverlayNavOptions: OuxOverlayNavOptions = new OuxOverlayNavOptions({
    entryRoute: null,
    enableMainSearch: true,
    enableSubSearch: true,
    enableSubFilter: true,
    navMainHeaderGroup: new OuxOverlayMainHeaderGroupModel({ headline: 'Tenant Records', subhead: 'Manage Validation Configs' }),
    filterOptions: new OuxOverlayNavFilterOptionsModel({ filterMainItemsOn: 'tenant', filterSubItemsOn: 'name', filterOne: 'Active', filterTwo: 'Inactive' }),
    apiRequestOptions: new OuxOverlayNavApiOptionsModel({ addAuthToken: true, addContentType: true }),
    searchOptions: new OuxOverlayNavSearchOptionsModel({ placeholderMain : 'Search items...', placeholderSub : 'Search items...' }),
    awaitMessage: 'Fetching Tenants...',
    showSubNav: false,
    overrideStoreState: true
  });

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private router: Router,
              private vcRef: ViewContainerRef,
              private ouxLayoutSvc: OuxLayoutService,
              private instanceSvc: InstanceService) {

    this.aside = this.vcRef.element.nativeElement;
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit(): void {
    this.instanceSvc.instanceMountedAt('AsideComponent', 'app-aside');
    this.getLayoutState();
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach( (sub: Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  /**
   * Route to root component
   */
  public goToRoot(event : any) : void {
    this.router.navigate(['/tenant']);
  }

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  /**
   * Adds --full-width class to aside template if showSideNav is false
   */
  private getLayoutState(): void {
    this.subscriptions.push(
      this.ouxLayoutSvc.getSideNavState()
        .subscribe( (state : boolean) => {
          this.showSideNav = state;
          this.handleLayoutChanges();
      })
    );
  }

  /**
   * Set class state based on inbound conditions
   */
   private handleLayoutChanges() {
    if (this.showSideNav == false) {
      this.aside.classList.add('--fullwidth');
    }
    else {
      this.aside.classList.remove('--fullwidth');
    }
  }

}
