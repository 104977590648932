/* Angular Imports */
import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule } from '@angular/router';
/* Feature Imports */
import { InterfaceComponent } from './interface.component';


/**
 * Define Interface Routing Instructions
 */
const interfaceRoutes: Routes = [

  {
    path: '',
    component: InterfaceComponent,
    children: [
      {
        path: 'tenant',
        loadChildren: () => import('./../routes/tenants/tenant.module').then(m => m.TenantModule)
      }
    ]
  }
  // {
  //   path: 'migrate',
  //   component: InterfaceComponent,
  //   children: [
  //     {
  //       path: 'dashboard',
  //       loadChildren: () => import('./../routes/dashboard/dashboard.module').then(m => m.DashboardModule)
  //     },
  //     {
  //       path: 'form',
  //       loadChildren: () => import('./../routes/form/form.module').then(m => m.FormModule)
  //     }
  //   ]
  // }

];


@NgModule({

  imports: [
    RouterModule.forChild(interfaceRoutes)
  ],

  exports: [
    RouterModule
  ]

})


export class InterfaceRoutingModule { }

/* Export Feature Components */
export const routedComponents = [
  InterfaceComponent
];
