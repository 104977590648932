/* Angular Import */
import {
  Component,
  OnInit } from '@angular/core';
/* Feature Imports */
import { OuxNotFoundPartialOptions } from '@cisco/oux-common';
/* Feature Imports */
import { InstanceService } from '../../shared/services/instance.service';


@Component({
  selector: 'app-404',
  templateUrl: 'notfound.component.html',
  styleUrls: ['notfound.component.scss'],
  host: {
    'class': 'app-notfound'
  }
})
export class NotFoundComponent implements OnInit {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  public setOptions: OuxNotFoundPartialOptions = new OuxNotFoundPartialOptions({
    // background: 'https://media3.giphy.com/media/l3q2RLQb1PBPo48FO/giphy.gif'
    // buttonLabel: 'Custom Label'
    // textColor: '#888888',
    // message: 'Some Custom Message',
    redirectUri: '/tenant'
  });

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private instanceSvc: InstanceService) {

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit(): void {
    this.instanceSvc.instanceMountedAt('NotFoundComponent', 'app-404');
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////


  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

}
