import { Injectable }                        from '@angular/core';
import { 
  ActivatedRouteSnapshot, 
  CanActivate,  
  NavigationExtras,  
  Router, 
  RouterStateSnapshot }                      from '@angular/router';
import { 
  OuxWindowRefService,
  OuxLoggerService }                         from '@cisco/oux-common';
import { Observable, Subscriber }            from 'rxjs';
import { AuthorizationModel }                from 'src/app/core/models/authorization.model';
import { AuthorizationService }              from 'src/app/core/services/authorization.service';
import { AuthorizationStore }                from 'src/app/core/stores/authorization.store';



@Injectable({ providedIn: 'root' })
export class CanComponentActivateService implements CanActivate {

  private windowRef: any;

  constructor(private router : Router,
              private ouxWindowSvc: OuxWindowRefService,
              private ouxLoggerSvc: OuxLoggerService,
              private acvAuthSvc: AuthorizationService,
              private avcAuthStore: AuthorizationStore) {

    this.windowRef = this.ouxWindowSvc.nativeWindow;           
  }
  
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

    let observer: Subscriber<boolean>;
    let canActivate$ = new Observable<boolean>(o => { observer = o; });

    // Initializes and sets user authorization details
    this.acvAuthSvc.loadUserAuthDetails()
      .subscribe(
        (response : AuthorizationModel) => {
          if (response) {
            // sets user details in sesion storage
            this.processOperation(response, observer, 'success');
          }
          else {
            // returns false - can not activate
            this.processOperation('Unknown Error', observer, 'error');
          }
        },
        (error : Error) => {
          // returns false - can not activate
          this.processOperation(error, observer, 'error');
        }
      )
      
    return canActivate$;
  }


  private processOperation(response: AuthorizationModel | any, observer: Subscriber<boolean>, operation: string) : void {
    
    const URL = this.router.url || '';

    switch (operation) {
      case 'success': {
        this.acvAuthSvc.setUserAuthDetails(response);
        this.avcAuthStore.setUserDetails(this.acvAuthSvc.getUserAuthDetails());
        observer.next(true);
        observer.complete();
        break;
      }
      case 'error': {
        this.ouxLoggerSvc.logDebug('CanComponentActivateService: Failed to retrieve user details', response);


        if (!URL || URL == '/') {
          this.logout('/login', {queryParams: {sessionTimeout: true}});
        } 
        else {
          this.router.navigate(['/unauthorized']);
        }

        observer.next(false);
        observer.complete();
        break;
      }
    } 
  }

  public logout(url : string, extras : NavigationExtras) : void {
    this.windowRef.sessionStorage.clear();
    this.router.navigate([url], extras);
  }

}