/* Angular Imports */
import {
  Component,
  OnInit,
  OnDestroy,
  Input }                                         from '@angular/core';
/* RxJS Imports */
import { Subscription }                           from 'rxjs';
/* OneUX Imports */
import { 
  OuxLoggerService, 
  OuxRouterService, 
  OuxConfigService }                              from '@cisco/oux-common';
/* Feature Imports */
import { OuxOverlayNavOptions }                   from './models/oux-overlay-nav.options';
import { OuxOverlayMainHeaderGroupModel }         from './models/oux-overlay-nav-main-header-group.model';
import { OuxOverlayNavFilterOptionsModel }        from './models/oux-overlay-nav-filter-options.model';
import { OuxOverlayNavService }                   from './services/oux-overlay-nav.service';
import { OuxOverlayNavApiOptionsModel }           from './models/oux-overlay-nav-api-options.model';
import { OuxOverlayNavStore }                     from './stores/oux-overlay-nav.store';
import { OuxOverlayNavMainModel }                 from './models/oux-overlay-nav-main.model';
import { OuxOverlayNavSearchOptionsModel }        from './models/oux-overlay-nav-search-options.model';
import { AuthorizationModel }                     from 'src/app/core/models/authorization.model';
import { AuthorizationStore }                     from 'src/app/core/stores/authorization.store';
import { TenantAuthModel }                        from 'src/app/core/models/tenant-auth.model';


@Component({
  selector : 'oux-overlay-nav',
  templateUrl : './oux-overlay-nav-base.component.html',
  styleUrls : [
    './oux-overlay-nav-base.component.scss'
  ],
  host: {
    class: 'oux-overlay-nav-base'
  },
})
export class OuxOverlayNavBaseComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  private environment : string = null;

  private tenantArray : TenantAuthModel[];
  private tenantString: string = '';

  /**
   * Represents the current active/selected nav item
   */
  public activeNavItem: string = null;
  public selectedItem: OuxOverlayNavMainModel;


  @Input('options')
  public options: OuxOverlayNavOptions = new OuxOverlayNavOptions({
    entryRoute: null,
    navItems: [],
    enableMainSearch: false,
    enableSubSearch: false,
    enableSubFilter: false,
    navMainHeaderGroup: new OuxOverlayMainHeaderGroupModel({ headline: 'Tenant Records', subhead: 'Configuration Manager' }),
    filterOptions: new OuxOverlayNavFilterOptionsModel({ filterMainItemsOn: 'tenant', filterSubItemsOn: 'name', filterOne: 'Active', filterTwo: 'Inactive' }),
    apiRequestOptions: new OuxOverlayNavApiOptionsModel({ addAuthToken: false, addContentType: false }),
    searchOptions: new OuxOverlayNavSearchOptionsModel({ placeholderMain : 'Search items...', placeholderSub : 'Search items...' }),
    awaitMessage: 'Fetching data...',
    showSubNav: false,
    overrideStoreState: false
  });

  ////////////////////////////////////////////////////
  // Inject Dependancies
  ////////////////////////////////////////////////////

  constructor(private ouxRouterSvc : OuxRouterService,
              private ouxLoggerSvc : OuxLoggerService,
              private ouxConfigSvc : OuxConfigService,
              private acvAuthStore : AuthorizationStore,
              private ouxOverlayNavSvc : OuxOverlayNavService,
              private ouxOverlayNavStore : OuxOverlayNavStore) {

    this.environment = this.ouxConfigSvc.getAppConfigValue('environment');

    // We extract the stored response and map it to our navItems key
    this.subscriptions.push(
      this.ouxOverlayNavStore.state$.subscribe(collection => {
        if (!collection) {
          return;
        }
        // the assignment
        this.options.navItems = collection;
        this.ouxLoggerSvc.logData(collection);
      })
    );

    /**
     * Ensure subnav closes when navigating back to tenant route
     */
     this.subscriptions.push(
      this.ouxRouterSvc.getRouteHistory()
        .subscribe(
          (history: string) => {
            if (history === '/tenant') {
              this.ouxOverlayNavStore.closeSubNav();
            }
          }
        )
    );

    this.subscriptions.push(
      this.acvAuthStore.state$
        .subscribe(
          (data : AuthorizationModel) => {
            if (data) {
              this.tenantArray = data.tenants;
              this.ouxLoggerSvc.logDebug('OuxOverlayNavBaseComponent: User Roles', this.tenantArray);
              
              let i = 0;
              while (i < this.tenantArray.length) {
                this.tenantString += (String(this.tenantArray[i].id)) + ",";
                i++;
              }
    
              this.tenantString = this.tenantString.slice(0, -1);
              this.fetchNavData(this.tenantString);
            }
          },
          // has error
          (error: Error) => { 
            this.ouxLoggerSvc.logDebug('OuxOverlayNavBaseComponent: Failed to load user role', error); 
          },
          // final
          () => {}
      )
    );
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////
  
  ngOnInit() : void {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////


  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  private fetchNavData(tenantString : string) : void {
    this.subscriptions.push(
      this.ouxOverlayNavSvc.getNavData(this.environment, tenantString).subscribe(_ => {
      })
    );
  }

}
