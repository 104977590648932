<aside class="aside slideInLeft animated">
  
  <oux-overlay-nav [options]="ouxOverlayNavOptions" data-selector="aside-z4OnirYDtR">
    <div class="logo" nav-partial="logo">
      <a class="logo__link" (click)="goToRoot($event.preventDefault())">
        <i class="icon icon-cisco"></i>
      </a>
      <div class="logo__meta">
        <h1 class="app-name">DVA Audit</h1>
        <p class="app-desc">Configuration Manager</p>
      </div>
    </div><!-- /partial-->

    <!-- optional call to action fragment transclution -->
    <div nav-partial="main-cta">
      <oux-button [options]="{ label: 'Create New Record', icon: 'icon-add-outline', style: 'stateless', disabled: true, dataSelector : 'aside-u3UUbHNjTS' }">
      </oux-button>
    </div>

    <!-- optional call to action fragment transclution -->
    <div nav-partial="sub-cta">
      <oux-button [options]="{ label: 'Add New Configuration', icon: 'icon-add-outline', style: 'flat', size: 'small', disabled: true, dataSelector : 'aside-EbunMrkC8e' }">
      </oux-button>
    </div>

  </oux-overlay-nav>
</aside>
