/* Angular Imports */
import { Injectable } from '@angular/core';

@Injectable()
export class TranslateUtilsService {

  constructor() { }

  /**
   * Creates a new array, based on a character seperated string
   * @param prop - Represents the property to transform.
   * @param sep - Represents the charactor(s) to use for separating the string
   * @param key - Represents the new interable key/value.
   */
  public buildNewArray(prop: any, sep: any, key?: string): any {
    // stand up new array
    const collection: any = new Array();
    // splits inbound string at each seperator, forming a new array of substrings
    let newArr = prop.split(sep);
    // set default prop name to key, if new name was not supplied
    let newKey = key ? key : 'key';
    // loops over each substring, pushing each value to the provided property name 
    for (let i = 0; i < newArr.length; i++) {
      collection.push({ [newKey] : newArr[i].trim() });
    }
    return collection;
  }


}
