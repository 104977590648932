<header>

  <oux-autocomplete 
    #autocomplete
    [icon]="'icon-search'" 
    [bordered]="false"
    [placeholder]="'Search...'"
    [themeOverrides]="autocompleteTheme" 
    (onSearch)="search($event)"
    data-selector="header-NqqLJ2c8Sl">
    <oux-autocomplete-group *ngFor="let group of states$ | async" [display]="group.tenant">
      <oux-autocomplete-option *ngFor="let state of group.configs" [value]="state.name">
        <a [routerLink]="[state.link]">
        <oux-autocomplete-highlight [display]="state.name" [highlight]="'startDifferences'" [search]="autocomplete.search"></oux-autocomplete-highlight>
      </a>
      </oux-autocomplete-option>
    </oux-autocomplete-group>
  </oux-autocomplete>

  <!-- <oux-global-nav [options]="setNavOptions" data-selector="header-tK8LyyR2yN"></oux-global-nav> -->
  <app-toolbar data-selector="header-fc9uqcbHfX"></app-toolbar>

</header>

