import { NgModule }                                 from '@angular/core';
import { Routes, RouterModule }                     from '@angular/router';
import { OuxAuthGuardService }                      from '@cisco/oux-common';

import { NotAuthorizedComponent }                   from './routes/notauthorized/notauthorized.component';
import { NotFoundComponent }                        from './routes/notfound/notfound.component';
import { PreloadSelectedModulesList }               from './core/preload-strategy';
import { CanComponentActivateService }              from './guards/activate/can-component-activate.service';

/**
 * Changed loading strategy from Lazy to Eager
 *
 * Notice the 'loadChildren` property below and the imported preloadingStrategy
 */
const appRoutes: Routes = [

  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },

  /**
   * The InterfaceModule is the acting root entry point for all feature routes
   * and layout components.
   *
   * Optional: Add canActivate to prevent unauthorized user from access this route.
   */
  {
    path: '',
    canActivate: [ 
      OuxAuthGuardService, 
      CanComponentActivateService
    ],
    loadChildren: () => import('./interface/interface.module').then(m => m.InterfaceModule),
    data: {
      preload: true
      // activities: ['Admin']
    }
  },

  /**
   * The GuardsModule is responsible for login/logout 
   * and guarding unauthorized user's from accesses certain views and API's 
   */
  {
    path: '',
    loadChildren: () => import('./guards/guards.module').then(m => m.GuardsModule),
    data: {
      preload: true
    }
  },

  /**
   * The router will select this route if the current user is not authorized to access a particular route
   * and/or the user doesn't have the necessary activities assigned to them.
   */
  {
    path: 'unauthorized',
    component: NotAuthorizedComponent
  },

  /**
   * The router will select this route if the requested URL doesn't match any of the defined paths
   */
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent
  }

];

@NgModule({

  imports: [
    RouterModule.forRoot(appRoutes, { enableTracing: false, preloadingStrategy: PreloadSelectedModulesList })
    //RouterModule.forRoot(appRoutes, { enableTracing: false, preloadingStrategy: PreloadSelectedModulesList, relativeLinkResolution: 'legacy' })
  ],

  exports: [
    RouterModule
  ],

  providers: [
    PreloadSelectedModulesList
  ]

})

export class AppRoutingModule { }
