/* Angular Imports */
import { 
  Component,
  OnDestroy }                       from '@angular/core'; 
/* RxJs Imports */
import { Subscription }             from 'rxjs';
/* OneUX Imports */
import { 
  OuxRadioButtonItemModel, 
  OuxThemeService }                 from '@cisco/oux-common'; 

 
@Component({ 
  selector: 'toolbar-theme', 
  templateUrl: './toolbar-theme.component.html', 
  styleUrls: ['./toolbar-theme.component.scss'], 
  host: { 
    'class': 'toolbar-theme' 
  } 
}) 
export class ToolbarThemeComponent implements OnDestroy { 

  private subscriptions: Subscription[] = []; 
 
  public selectedTheme: string = this.ouxThemeSvc.theme; 
  public themeRadioItems: { primary : string, background : string, radio : OuxRadioButtonItemModel }[] = []; 
 
  constructor(private ouxThemeSvc : OuxThemeService) {

    this.ouxThemeSvc.themes.forEach(theme => { 
      this.themeRadioItems.push({ 
        primary: theme.properties.primary, 
        background: theme.properties.background, 
        radio: new OuxRadioButtonItemModel({ 
          value: theme.name, 
          display: theme.display 
        }) 
      }); 
    }); 
  } 
 
  public ngOnDestroy(): void { 
    this.subscriptions.forEach(x => x.unsubscribe()); 
    this.subscriptions = []; 
  } 
 
  public selectTheme(): void { 
    this.ouxThemeSvc.setTheme(this.selectedTheme); 
  }

} 