/* Angular Imports */
import {
  Component,
  OnInit,
  OnDestroy,
  ViewContainerRef }               from '@angular/core';
/* RxJS Imports */
import { Subscription }            from 'rxjs';
/* Package Imports */
import { OuxLayoutService }        from '@cisco/oux-common';
/* Feature Imports */
import { InstanceService }         from '../../shared/services/instance.service';


/**
 * Located inside <app-body> under <app-hero>
 */
@Component({
  selector: 'app-shelf',
  templateUrl: './shelf.component.html',
  styleUrls: ['./shelf.component.scss'],
  host: {
    'class': 'app-shelf'
  }
})
export class ShelfComponent implements OnInit, OnDestroy {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  /**
   * Manage subscription - remember to unsubscribe when component is destroyed
   */
  private subscriptions : Subscription[] = [];
  /**
   * Stand up native element ref
   */
  private shelf : Node | any;

  /**
   * Is shelf present
   */
  public showShelf : boolean = null;

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private vcRef : ViewContainerRef,
              private layoutSvc : OuxLayoutService,
              private instanceSvc : InstanceService) {

    this.shelf = this.vcRef.element.nativeElement;

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {
    this.instanceSvc.instanceMountedAt('ShelfComponent', 'app-shelf');
    this.setShelfState();
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach( (sub : Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  /**
   * Hides our Shelf Container located inside the Interface Template
   */
  private setShelfState() : void {
    this.subscriptions.push(
      this.layoutSvc.getShelfState()
        .subscribe(
          (state : boolean) => {
            this.showShelf = state;
        })
    );
  }

}
