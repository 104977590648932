import { 
  Component, 
  OnInit,
  Input, 
  Renderer2, 
  OnDestroy }                                   from '@angular/core';
import { Subscription }                         from 'rxjs';
import { OuxOverlayNavMainModel }               from '../../models/oux-overlay-nav-main.model';
import { OuxOverlayNavOptions }                 from '../../models/oux-overlay-nav.options';
import { OuxOverlayNavStore }                   from '../../stores/oux-overlay-nav.store';


@Component({
  selector : 'oux-overlay-nav-main',
  templateUrl : './oux-overlay-nav-main.component.html',
  styleUrls : [
    './oux-overlay-nav-main.component.scss'
  ],
  host: {
    class: 'oux-overlay-nav-main'
  }
})
export class OuxOverlayNavMainComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  ////////////////////////////////////////////////////
  // Define bindable members
  ////////////////////////////////////////////////////

  /**
   * Represents the current active/selected nav item
   */
  public activeNavItem : string = null;

  public searchValue : string = null;

  /**
   * Options (i.e. component config) passed down from OuxOverlayNavBaseComponent
   */
  @Input('options') 
  public options : OuxOverlayNavOptions

  ////////////////////////////////////////////////////
  // Inject Dependancies
  ////////////////////////////////////////////////////

  constructor(private ouxOverlayNavStore : OuxOverlayNavStore,
              private renderer : Renderer2) {


  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public toggleSubnav(navItem: OuxOverlayNavMainModel, event : any) : void {
    // Reset active subnav items when a new navitem is selected
    this.activeNavItem = null;

    // If navitem does not have any child items - return
    if (typeof(navItem.configs) === 'undefined' || !navItem.configs || navItem.configs.length === 0) { 
      return; 
    }
    // Set activeNavItem to passed in navItem caption value
    this.activeNavItem = navItem.tenant;

    /**
     * Push selected navItem id to corresponding store
     * This filters out and creates a new array based on the provided filter criteria
     */
    this.ouxOverlayNavStore.setSelectedSubNavData(this.options.filterOptions.filterMainItemsOn, this.activeNavItem);

    /**
     * Init showNav behavior stream
     */
    this.subscriptions.push(
      this.ouxOverlayNavStore.showSubNav$.subscribe(value => {
        this.options.showSubNav = value;
      })
    );

    // reset active class
    this.activeNavItem = null;
  }

  /**
   * Update emitted search value so our template can react and respond to change when necessary
   */
  public search(value: string) : void {
    this.searchValue = value;
  }

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  /**
   * Responsible for animating our search result items in one-by-one
   * Animates top down
   */
  // private animateIn() : void {

  //   this.subscriptions.push(
  //     this.listItemContainer.changes.subscribe( () => {
  //       let i = 0,
  //           items = this.listItemContainer.toArray();

  //       let interval = setInterval( () => {
  //         if (i >= items.length) {
  //           clearInterval(interval);
  //           interval = null;
  //         } 
  //         else {
  //           this.renderer.addClass(items[i].nativeElement, 'fadeInDown');
  //           i++;
  //         }
  //       }, 100);
  //     })
  //   );
  // }

}