import { TenantAuthModel }                    from "./tenant-auth.model";
import { ActivitiesModel }                    from "./activities.model";

export class AuthorizationModel {

  public userId: string;
  public role: string;
  public tenants: TenantAuthModel[];
  public isActive: boolean; 
  public addedBy: string;
  public createDate: string;
  public lastUpdateDate: string;
  public activities: ActivitiesModel;

  constructor(args: Partial<AuthorizationModel>) {
    Object.assign(this, args);
  }

}