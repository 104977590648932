<nav class="sub">

  <div class="sub__header">
    <div class="flex --center-items --even-spaced">
      <button 
        class="btn --stateless --close"
        (click)="closeSubNav()"  
        data-selector="oux-overlay-nav-sub-GsC2L2jSTN">
        <i class="icon-left-arrow"></i>
        <i class="icon-remove"></i>
      </button>
      <h3 class="headline ellipsis" data-selector="oux-overlay-nav-main-ysTtGdunkf">
        {{ subListHeaderText | normalize }}
      </h3>
      <button 
        class="btn --stateless"
        (click)="toggleOptions()"
        data-selector="oux-overlay-nav-sub-WjzNcg9Oy7">
        <i class="icon-more"></i>
      </button>
    </div><!-- /flex -->
    <p class="subhead" data-selector="oux-overlay-nav-main-jfZbEh2MBD">{{ subListSubHeaderText }}</p>
    <!-- single slot transclusion for arbitrary code --> 
    <ng-content select="[nav-partial=sub-cta]"></ng-content>
  </div><!-- /sub__header -->

  <!-- partial transclution for nav search -->
  <ng-container *ngIf="options.enableSubSearch">
    <oux-overlay-nav-search
      class="mrg-tb-md"
      [placeholder]="options.searchOptions.placeholderSub" 
      (onSearch)="search($event)"
      data-selector="oux-overlay-nav-sub-tGM3jEGlpC">
    </oux-overlay-nav-search>
  </ng-container>

  <ng-container [ngSwitch]="options.enableSubFilter">
    
    <ng-template [ngSwitchCase]="true">
      <div class="filter">
        <oux-tabs (onSelect)="setSelectedTab($event)" [mode]="'render-nav'">
          <oux-tab [label]="options.filterOptions.filterOne" [metadata]="filterOneItemCount">
            <oux-overlay-nav-sub-list 
              [data]="filterOneData"
              [selectedFilter]="selectedTab" 
              [searchOn]="searchValue"
              data-selector="oux-overlay-nav-sub-WOi2MASG3q">
            </oux-overlay-nav-sub-list>
          </oux-tab>
          <oux-tab [label]="options.filterOptions.filterTwo" [metadata]="filterTwoItemCount">
            <oux-overlay-nav-sub-list 
              [data]="filterTwoData"
              [selectedFilter]="selectedTab" 
              [searchOn]="searchValue"
              data-selector="oux-overlay-nav-sub-86VlTwGZt3">
            </oux-overlay-nav-sub-list>
          </oux-tab>
        </oux-tabs>
      </div>
    </ng-template>

   <ng-template [ngSwitchCase]="false">
      <!-- offers sub items flat -->
      <oux-overlay-nav-sub-list 
        [data]="subListData" 
        [searchOn]="searchValue"
        data-selector="oux-overlay-nav-sub-i48I1pqtaI">
      </oux-overlay-nav-sub-list>
    </ng-template>
  </ng-container>

</nav><!-- /sub -->
