/* Angular Imports */
import {
  Component,
  OnInit,
  OnDestroy,
  ViewContainerRef }                                      from '@angular/core';
import { Router }                                         from '@angular/router';
/* RxJS Imports */
import { 
  Subscription, 
  BehaviorSubject }                                       from 'rxjs';
/* Package Imports */
import {
  OuxLayoutService,
  OuxGlobalNavOptions,
  OuxRouterService,
  OuxLoggerService,
  OuxGlobalNavType,
  OuxAutocompleteThemeOverrides, 
  OuxConfigService }                                      from '@cisco/oux-common';
/* Feature Imports */
import { InstanceService }                                from '../../shared/services/instance.service';
import { SharedDataService }                              from 'src/app/shared/services/shared-data.service';
import { OuxOverlayNavStore }                             from '../oux-overlay-nav/stores/oux-overlay-nav.store';
import { OuxOverlayNavOptions }                           from '../oux-overlay-nav/models/oux-overlay-nav.options';
import { AuthorizationModel }                             from 'src/app/core/models/authorization.model';
import { AuthorizationStore }                             from 'src/app/core/stores/authorization.store';


class ConfigGroup {
  public name: string;
  public link: string;

  constructor(options: Partial<ConfigGroup>) {
    Object.assign(this, options);
  }
}

class StateGroup {
  public tenant: string; // reps tenant key
  public configs: ConfigGroup[];

  constructor(options: Partial<StateGroup>) {
    Object.assign(this, options);
  }
}

/**
 * Component Decorator - provides metadata describing the Component
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    'class': 'app-header slideInDown animated'
  }
})

export class HeaderComponent implements OnInit, OnDestroy {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  /**
   * Manage subscription - remember to unsubscribe when component is destroyed
   */
  private subscriptions : Subscription[] = [];
  /**
   * Stand up native element ref
   */
  private header : HTMLElement | any;
  private environment: string = null;
  private tenantString = '';
  private tenantArray = [];
  public searchValue: string = null;

  /**
   * Is side nav present
   */
  public showSideNav : boolean = null;
  /**
   * Manage Header Component Instance
   */
  public headerInstance : string = null;

  public groupedItems: StateGroup[] = [];
  public states$: BehaviorSubject<StateGroup[]> = new BehaviorSubject<StateGroup[]>([]);

  /**
   * Set Global Nav Options
   */
  public setNavOptions : OuxGlobalNavOptions = new OuxGlobalNavOptions({
    type: OuxGlobalNavType.Underlined,
    endPoint: '/api/global-nav.json',
    show: true
  });
  /**
   * Autocomplete Theme Overrides
   */
  public autocompleteTheme: OuxAutocompleteThemeOverrides = {
    backgroundColor: 'var(--background)',
    borderColor: '',
    borderColorFocused: '',
    color: '',
    placeholderColor: '',
    shadowFocused: ''
  };

  public options : OuxOverlayNavOptions = new OuxOverlayNavOptions({});

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private route: Router,
              private vcRef : ViewContainerRef,
              private ouxLayoutSvc : OuxLayoutService,
              private ouxRouterSvc : OuxRouterService,
              private ouxConfigSvc : OuxConfigService,
              private ouxLoggerSvc : OuxLoggerService,
              private ouxOverlayNavStore : OuxOverlayNavStore,
              private instanceSvc : InstanceService,
              private sharedDataSvc : SharedDataService,
              private acvAuthStore : AuthorizationStore) {

    this.header = this.vcRef.element.nativeElement;
    this.environment = this.ouxConfigSvc.getAppConfigValue('environment');

    this.setHeaderInstance();

    this.subscriptions.push(
      this.acvAuthStore.state$
        .subscribe(
          (data : AuthorizationModel) => {
            if (data) {
              this.tenantArray = data.tenants;
              this.ouxLoggerSvc.logDebug('HeaderComponent: User Roles', this.tenantArray);
    
              let i = 0;
              while (i < this.tenantArray.length) {
                this.tenantString += (String(this.tenantArray[i].id)) + ",";
                i++;
              }
    
              this.tenantString = this.tenantString.slice(0, -1);
    
              this.sharedDataSvc.getACVData(this.environment, this.tenantString)
                .subscribe(data => {
                  data.map(i => this.groupedItems.push(new StateGroup({
                    tenant: i.tenant,
                    configs: i.configs.map(j => new ConfigGroup({
                      name: j.name,
                      link: j.link
                    }))
                  })))
                })
            }
          },
          // has error
          (error: Error) => { 
            this.ouxLoggerSvc.logDebug('HeaderComponent: Failed to load user role', error); 
          },
          // final
          () => {}
        )
    );

    this.states$.next(this.groupedItems);
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {
    this.instanceSvc.instanceMountedAt('HeaderComponent', 'app-header');
    this.getLayoutState();
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach( (sub : Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  /**
   * Dynamically Get/Set new inboud route title
   */
  private setHeaderInstance() : void {

    this.subscriptions.push(
      this.ouxRouterSvc.getActiveRouteData()
        .subscribe(
          (data : any) => {
            this.ouxLoggerSvc.logDebug('Draw Header Instance:', data.title);
            this.headerInstance = data.title;
        })
    );

  }

  /**
   * Adds --full-width class to header template if showSideNav is false
   */
  private getLayoutState() : void {

    this.subscriptions.push(
      this.ouxLayoutSvc.getSideNavState()
        .subscribe(
          (state : boolean) => {
            this.showSideNav = state;
            this.handleLayoutChanges();
        })
    );

  }

  /**
   * Set class state based on inbound conditions
   */
  private handleLayoutChanges() : void {

    if (this.showSideNav == false) {
      this.header.classList.add('--fullwidth');
    }
    else {
      this.header.classList.remove('--fullwidth');
    }
  }


  public search(value: string) : void {

    let filtered: StateGroup[] = this.groupedItems;

    if (value) {
      filtered = this.groupedItems
        .map(group => {
          return new StateGroup({
            tenant: group.tenant,
            configs: group.configs.filter(state => state.name.toLowerCase().includes(value.toLowerCase()))
          });
        })
        .filter(group => group.configs.length > 0);
    }

    this.states$.next(filtered);

    for (var i = 0; i < filtered[0].configs.length; i++) {

      if (value.toLowerCase() == filtered[0].configs[i].name.toLowerCase()) {
        this.ouxOverlayNavStore.setSelectedSubNavData('tenant', filtered[0].tenant);
        this.route.navigate([filtered[0].configs[i].link]);
      }
    }
  }

}
