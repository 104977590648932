/* Angular Imports */
import { Injectable }                       from '@angular/core';
import { 
  HttpClient, 
  HttpHeaders }                             from '@angular/common/http';
import { Observable }                       from 'rxjs';
import { 
  tap, 
  catchError, 
  map,  
  shareReplay }                             from 'rxjs/operators';
/* OneUX Imports */
import {  
  OuxExceptionsHandlerService,
  OuxExceptionsHandleError, 
  OuxConfigService,
  OuxAuthenticationService}                 from '@cisco/oux-common';
/* Feature Imports */
import { OuxOverlayNavMainModel }           from '../models/oux-overlay-nav-main.model';
import { OuxOverlayNavStore }               from '../stores/oux-overlay-nav.store';

/* Package imports */



@Injectable()
export class OuxOverlayNavService {

  private gatewayUri : string;
  private auditConfigUri : string;

  /**
   * Create service mapping for http exception handling
   */
  private ouxHandleError : OuxExceptionsHandleError = this.ouxExceptionsSvc.createHandleError('OuxOverlayNavService');

  constructor(private http: HttpClient,
              private ouxConfigSvc : OuxConfigService,
              private ouxAuthSvc : OuxAuthenticationService,
              private ouxExceptionsSvc : OuxExceptionsHandlerService,
              private ouxOverlayNavStore : OuxOverlayNavStore) {

    this.gatewayUri = this.ouxConfigSvc.getAppConfigValue('gatewayUri');
    this.auditConfigUri = this.ouxConfigSvc.getAppConfigValue('apiUri').auditConfigsFilter;
  }

  /**
   * Retrieves a list of nav items
   */
  public getNavData(environment: string, tenants : string) : Observable<OuxOverlayNavMainModel[]> {

    const URL : string = `${this.gatewayUri}${this.auditConfigUri}`;
    const OPTIONS = this.getACVOptions(environment, tenants);

    const request$ = this.http
      .get<OuxOverlayNavMainModel[]>(URL, OPTIONS)
      .pipe(
        shareReplay(),
        map((response : OuxOverlayNavMainModel[])  => { 
          return response.map(item => new OuxOverlayNavMainModel(item)); 
        }),
        tap( (response : OuxOverlayNavMainModel[]) => {
          // is using original OuxOverlayNavMainModel
          this.ouxOverlayNavStore.setNavData(response);
        }),
        catchError(error => {
          this.ouxOverlayNavStore.setNavData(null);
          // create operation mapping for http exception handling
          return this.ouxHandleError('getNavItems', error)(error);
        })
      );

    return request$;
  }

  /**
   * Stages our Http Request Headers
   */
  private getACVOptions(environment: string, tenants: string) : { headers: HttpHeaders} {

    const OPTIONS : { headers : HttpHeaders } = {
      headers : new HttpHeaders()
      .set('Authorization', this.ouxAuthSvc.getAuthToken())
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json')
      .append('Environment', environment )
      .append('Tenants', tenants)
    };
    return OPTIONS;
  }



}