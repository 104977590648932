import { Pipe, PipeTransform }     from '@angular/core';

@Pipe({
  name: 'normalize',
  pure: true // enables synchronous filter - Note: may impact performance if item count is too large 
})

export class NormalizeStringPipe implements PipeTransform {
 
  transform(value : string) : string {
    if (!value) { 
      return; 
    }
    
    return value.replace(/[_-]/g, " ");
  }

}
