export class OuxOverlayNavFilterOptionsModel {

  public filterMainItemsOn?: string;
  public filterSubItemsOn?: string;
  public filterOne: string;
  public filterTwo: string;

  constructor(opts: Partial<OuxOverlayNavFilterOptionsModel>) {
    Object.assign(this, opts);
  }
}