export class OuxOverlayNavMainModel {

  public tenant: string;
  public configs: AcvConfigItems[];

  constructor(opts: Partial<OuxOverlayNavMainModel>) {
    Object.assign(this, opts);
  }
}



export class AcvConfigItems {

  public name : string;
  public link : string;
  public active : boolean;
  public auditDescription? : string;

  constructor(opts: Partial<AcvConfigItems>) {
    Object.assign(this, opts);
  }

}