<nav class="main">

  <div class="main__header">
    <h3 class="headline">{{ options.navMainHeaderGroup.headline }}</h3>
    <p class="subhead">{{ options.navMainHeaderGroup.subhead }}</p>
  </div><!-- /main__header -->

  <!-- partial transclution for nav search -->
  <ng-container *ngIf="options.enableMainSearch">
    <oux-overlay-nav-search 
      [placeholder]="options.searchOptions.placeholderMain" 
      (onSearch)="search($event)">
    </oux-overlay-nav-search>
  </ng-container>

  <!-- single slot transclusion for arbitrary code --> 
  <ng-content select="[nav-partial=main-cta]"></ng-content>

  <!-- Parent List -->
  <ul class="main__items" *ngIf="( options.navItems && options.navItems.length ); else exceptionTemplate"
  data-selector="oux-overlay-nav-main-CoA3OScx0e">
    <li 
      class="item"
      *ngFor="let navItem of options.navItems | ouxSearchFilter : searchValue : 'tenant'"
      (click)="toggleSubnav(navItem, $event.preventDefault())">
      <a 
        href=""
        class="item__link"
        [ngClass]="{ 'is-active' : navItem.tenant === activeNavItem }"
        [attr.data-selector]="navItem.tenant">
        <div class="link__group">
          <span class="item__name ellipsis">
            {{ navItem.tenant }}
          </span>
          <span class="item__count">
            {{ navItem?.configs?.length ? navItem.configs.length : '0' }}
          </span>
        </div>
      </a>
    </li><!-- /navItem loop -->
    <li class="item flex --center-items" *ngIf="(options.navItems | ouxSearchFilter : searchValue : 'tenant').length === 0;"
    data-selector="oux-overlay-nav-main-Zus4OGDVlz">
      <p class="message fadeInDown animated">Hmm... Try using a different search term.</p>
    </li>
  </ul>

  <!-- Await template -->
  <ng-template #exceptionTemplate>
    <ul class="main__items" *ngIf="!options.navItems" data-selector="oux-overlay-nav-main-Gxbflmn52I">
      <li class="item flex --center-items --has-spinner">
        <oux-spinner 
          class="fadeIn animated"
          [show]="!options.navItems" 
          [hasOverlay]="false" 
          [size]="'small'">
        </oux-spinner>
        {{ options.awaitMessage }}
      </li>
    </ul>
  </ng-template>

</nav>
