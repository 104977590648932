/* Angular Imports */
import { 
  Component, 
  OnInit, 
  OnDestroy}                                        from '@angular/core';
import { Subscription }                             from 'rxjs';
/* Package Imports */
import { ToolbarSettingsComponent }                 from '../toolbar-settings/toolbar-settings.component';
import { AuthorizationModel }                       from 'src/app/core/models/authorization.model';
import { AuthorizationStore }                       from 'src/app/core/stores/authorization.store';



@Component({
  selector: 'toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss'],
  host: {
    'class': 'toolbar-user'
  }
})
export class ToolbarUserComponent implements OnInit, OnDestroy {

  private subscriptions : Subscription[] = [];

  /**
   * Represents the users CEC username
   */
  public currentUser : string = null;

  /**
   * Represents the users parsed initials
   * Note: Will only show if the users image is not available
   */
  public userInitial : string = null;
  /**
   * Represents the users CEC directory image
   */
  public userAvatar : any;
  /**
   * Determines if the users initials should show in place of their image 
   */
  public setInitials : boolean = false;
  /**
   * Represents the injected tooltip component
   */
  public settingsComponent = ToolbarSettingsComponent;
  /**
   * Manages dropdown state
   */
  public close : boolean = false;

  public userId = '';

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private acvAuthStore : AuthorizationStore) {

    this.subscriptions.push(
      this.acvAuthStore.state$
        .subscribe(
          (data : AuthorizationModel) => {
            if (data) {
              this.setCurrentUser(data);
            }
          }
        )
    );
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {}

  ngOnDestroy(): void {
    this.subscriptions.forEach( (sub : Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public toggleDropdown() : void {
    this.close = !this.close;
  }

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  private setCurrentUser(data: AuthorizationModel) : void {
    this.currentUser = data.userId;
    this.setUserAvatar(data.userId);
  }

  private setUserAvatar(username : string) : void {
    this.userInitial = username.charAt(0).toUpperCase();
    this.userAvatar = `//wwwin.cisco.com/dir/photo/std/${username}.jpg`; 
    this.validateImageSrc(username);
  }

  private validateImageSrc(username : string) : void {
    let img = new Image(); 
    img.onload = () => {}; 
    img.onerror = () => {
      this.setInitials = true;
    };
    img.src = `//wwwin.cisco.com/dir/photo/std/${username}.jpg`;
  }

}


