/* Angular Imports */
import { Injectable }       from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest }             from '@angular/common/http';
/* RxJS Imports */
import { Observable }       from 'rxjs';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request);

  }

}
