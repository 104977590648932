import { NgModule }                           from '@angular/core';
import { CommonModule }                       from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule }                          from '@angular/common/http';
/* OneUX Imports */
import { OuxExceptionsModule }                from '@cisco/oux-common';
/* Import Interceptors Deps */
import { AuthErrorInterceptor }               from './interceptors/auth-error.interceptor';
import { LoaderService }                      from './services/loader.service';
import { LoaderInterceptor }                  from './interceptors/loader.interceptor';



@NgModule({

  imports: [
    CommonModule,
    HttpClientModule,
    OuxExceptionsModule
  ],

  providers: [
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthErrorInterceptor,
      multi: true
    }
  ],

  declarations: []

})

export class CoreModule { }
