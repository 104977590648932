/* Angular Imports */
import { NgModule }                             from '@angular/core';
import { CommonModule }                         from '@angular/common';
import { RouterModule }                         from '@angular/router';
import { FormsModule }                          from '@angular/forms';
/* OneUX Imports */
import {
  OuxGlobalNavModule,
  OuxAutocompleteModule }                       from '@cisco/oux-common';
/* Per Dependancies */
import { SharedModule }                         from '../shared/shared.module';
/* Feature Imports */
import { HeaderComponent }                      from './header/header.component';
import { ToolbarComponent }                     from './toolbar/toolbar.component';
import { ToolbarModalComponent }                from './toolbar/toolbar-modal/toolbar-modal.component';
import { ToolbarUserComponent }                 from './toolbar/toolbar-user/toolbar-user.component';
import { ToolbarSettingsComponent }             from './toolbar/toolbar-settings/toolbar-settings.component';
import { ToolbarThemeComponent }                from './toolbar/toolbar-theme/toolbar-theme.component';
import { BreadcrumbsComponent }                 from './breadcrumbs/breadcrumbs.component';
import { CrumbContainerDirective }              from './breadcrumbs/directives/crumb-container.directive';
import { AsideComponent }                       from './aside/aside.component';
import { BodyComponent }                        from './body/body.component';
import { HeroComponent }                        from './hero/hero.component';
import { ShelfComponent }                       from './shelf/shelf.component';
import { FooterComponent }                      from './footer/footer.component';
import { RootLogoutComponent }                  from '../guards/logout/logout.component';
/* Begin - New Overlay Nav Imports */
import { 
  OuxOverlayNavBaseComponent,
  OuxOverlayNavMainComponent,
  OuxOverlayNavSubComponent,
  OuxOverlayNavSubListComponent,
  OuxOverlayNavSearchComponent,
  OuxOverlayNavListItemDirective,
  OuxOverlayNavSearchPipe,
  OuxOverlayNavService,
  OuxOverlayNavStore }                          from './oux-overlay-nav';
/* End - New Overlay Nav Imports */


@NgModule({

  imports: [
    CommonModule,
    RouterModule,
    OuxGlobalNavModule,
    OuxAutocompleteModule,
    SharedModule
  ],

  declarations: [
    HeaderComponent,
    ToolbarComponent,
    ToolbarModalComponent,
    ToolbarUserComponent,
    ToolbarSettingsComponent,
    ToolbarThemeComponent,
    BreadcrumbsComponent,
    CrumbContainerDirective,
    AsideComponent,
    BodyComponent,
    HeroComponent,
    ShelfComponent,
    FooterComponent,
    RootLogoutComponent,

    OuxOverlayNavBaseComponent,
    OuxOverlayNavMainComponent,
    OuxOverlayNavSubComponent,
    OuxOverlayNavSubListComponent,
    OuxOverlayNavSearchComponent,
    OuxOverlayNavListItemDirective,
    OuxOverlayNavSearchPipe,
  ],

  providers: [
    OuxOverlayNavService,
    OuxOverlayNavStore
  ],

  entryComponents: [
    ToolbarModalComponent,
    ToolbarSettingsComponent
  ],

  exports: [
    RouterModule,
    HeaderComponent,
    BreadcrumbsComponent,
    AsideComponent,
    BodyComponent,
    HeroComponent,
    ShelfComponent,
    FooterComponent,
    RootLogoutComponent,

    OuxOverlayNavBaseComponent
  ]

})

export class LayoutModule { }
