<div class="row txt-right">
  <oux-button 
    class="mrg-right-sm"
    [options]="{ label : 'Cancel', style : 'stateless', color : 'neutral', dataSelector : 'logout-9gFufkGUX9' }"
    (trigger)="onCancel()">
  </oux-button>
  <oux-button 
    [options]="{ label : 'Logout', color: 'primary', dataSelector : 'logout-bKPmigErmn' }"
    (trigger)="onLogout()">
  </oux-button>
</div>