import { 
  Component, 
  Input, 
  Output, 
  EventEmitter }  from '@angular/core';


@Component({
  selector: 'oux-overlay-nav-search',
  templateUrl: './oux-overlay-nav-search.component.html',
  styleUrls: [
    './oux-overlay-nav-search.component.scss'
  ],
  host: {
    'class': 'oux-overlay-nav-search' 
  }
})
export class OuxOverlayNavSearchComponent {

  public searchValue: string = '';

  @Input('placeholder')
  public placeholder: string;

  @Output('onSearch')
  public onSearch : EventEmitter<string>;

  constructor() {
    this.onSearch = new EventEmitter();
  }

  public onSearchChange() : void {
    this.onSearch.emit(this.searchValue);
  }

  /**
   * Responsible for clearing the search field and reseting the list
   */
  public onClearSearch() : void {
    this.searchValue = '';
    this.onSearch.emit(this.searchValue);
  }

}
