<div class="breadcrumbs" *ngIf="showBreadcrumbs">
  <ul class="list --bare" data-selector="breadcrumb-x3xkoSQ3JF">
    <li
      class="fadeInLeft animated"
      *ngFor="let crumb of crumbs; let last = last; let i = index;">
      <a
        class="crumb"
        (click)="goTo(i)"
        [ngClass]="{ 'is-active' : last }"
        [attr.data-route]="getRoutePath(i)"
        [attr.data-selector]="crumb">{{ crumb }}
      </a>
      <span *ngIf="!last" class="solidus">/</span>
    </li>
  </ul>
</div>
